import React from 'react'
import { Link } from 'gatsby'

const Styles = require('../styles/pages/404.module.styl')

class NotFoundPage extends React.Component {
  render() {
    return (
      <div className={Styles['content']}>
        <p className={Styles['title']}>404</p>
        <p className={Styles['des']}> Ohhhhhhhhhhh! this page is gone! </p>
        <Link className={Styles['back']} to='/posts'>
          I Wanna Go Home !
        </Link>
      </div>
    )
  }
}

export default NotFoundPage
